import { OTHER_COMPANY, OTHER_COMPANY_ID } from "../../../modules/Registration/ui/common/constants";

function formatUserInfoVO(obj) {
    const userProfile = {};
    if (obj.userVO) {
        userProfile.firstName = obj.userVO.firstName ? obj.userVO.firstName : "";
        userProfile.lastName = obj.userVO.lastName ? obj.userVO.lastName : "";
        userProfile.email = obj.userVO.email ? obj.userVO.email : "";
        userProfile.middleName = obj.userVO.middleName ? obj.userVO.middleName : "";
        userProfile.companyName = obj.userVO.company ? obj.userVO.company.companyName : null;
        userProfile.jobTitle = obj.userVO.jobTitle ? obj.userVO.jobTitle : "Other";
        userProfile.phone = obj.userVO.phone ? obj.userVO.phone : "";
        userProfile.mobile = obj.userVO.mobile ? obj.userVO.mobile : "";
        userProfile.addressLine1 = obj.userVO.addressLine1 ? obj.userVO.addressLine1 : "";
        userProfile.addressLine2 = obj.userVO.addressLine2 ? obj.userVO.addressLine2 : "";
        userProfile.city = obj.userVO.city ? obj.userVO.city : "";
        userProfile.state = obj.userVO.stateName ? obj.userVO.stateName : "";
        userProfile.province = obj.userVO.province ? obj.userVO.province : "";
        userProfile.postalCode = obj.userVO.postalCode ? obj.userVO.postalCode : "";
        userProfile.country = obj.userVO.country ? obj.userVO.country.countryId : "";
        // userProfile.timezone = obj.userVO.timezone ? obj.userVO.timezone : "";
        userProfile.countryName = obj.userVO.country ? obj.userVO.country.countryName : "";
        userProfile.customerId = obj.userVO.id ? obj.userVO.id : "";
        userProfile.supportId = obj.userVO.supportId ? obj.userVO.supportId : [];
        userProfile.sourceId = obj.userVO.sourceId ? obj.userVO.sourceId : [];
        userProfile.registrationId = obj.userVO.registrationId ? obj.userVO.registrationId : null;
        userProfile.broadcomEmail = obj.userVO.broadcomContactEmail
            ? obj.userVO.broadcomContactEmail
            : "";
        userProfile.companyId = obj.userVO.company ? obj.userVO.company.companyId : null;
        userProfile.otherCompanyName = obj.userVO.otherCompanyName
            ? obj.userVO.otherCompanyName
            : null;
        userProfile.fax = obj.userVO.faxNumber ? obj.userVO.faxNumber : null;
        userProfile.defaultCurrency = obj.userVO.defaultCurrency
            ? obj.userVO.defaultCurrency
            : null;
        userProfile.isEnterprise =
            obj.userVO.gtmScreened === "Y" || !obj.userVO.gtmScreened ? true : false;
        userProfile.isEnterpriseRejPend = !obj.userVO.gtmScreened || obj.userVO.gtmScreened==="N" ? true : false;
        userProfile.isActive = obj.userVO.activeFlag ? obj.userVO.activeFlag === "Y" : false;

        const companyVO = {};
        const list = [];

        const companyId = userProfile.companyId;

        if (obj.companyVO) {
            obj.companyVO.map((item: any) => {
                list.push({ label: item.companyName, value: item.companyId });
            });
        }

        if (companyId === OTHER_COMPANY_ID && obj.userVO.otherCompanyName) {
            companyVO.isDisabled = true;
            companyVO.fieldType = "text";
            userProfile.companyName = OTHER_COMPANY;
        } else if (companyId === OTHER_COMPANY_ID) {
            userProfile.companyName = OTHER_COMPANY;
            //if company list contains, company other than "other", show drop down
            if (list.length && list[0].value !== OTHER_COMPANY_ID) {
                companyVO.fieldType = "select";
            } else {
                if (userProfile.companyName === "Other") {
                    // userProfile.companyName = "";
                }
                companyVO.fieldType = "text";
            }
            companyVO.isDisabled = false;
            companyVO.companyId = companyId;
        } else if (companyId === "") {
            if (obj.companyVO) {
                companyVO.isDisabled = false;
                companyVO.fieldType = "select";
                companyVO.companyId = companyId;
            } else {
                companyVO.isDisabled = false;
                companyVO.fieldType = "text";
                companyVO.companyId = companyId;
            }
        } else {
            companyVO.isDisabled = true;
            companyVO.fieldType = "select";
            companyVO.companyId = companyId;
        }
        companyVO.options = list;
        userProfile.company = obj.userVO.company ? companyVO : {};
    }
    else if (obj.basicUserVO) 
    {
        userProfile.firstName = obj.basicUserVO.firstName ? obj.basicUserVO.firstName : "";
        userProfile.lastName = obj.basicUserVO.lastName ? obj.basicUserVO.lastName : "";
        userProfile.email = obj.basicUserVO.email ? obj.basicUserVO.email : "";
        userProfile.companyName = obj.basicUserVO.company ? obj.basicUserVO.company.companyName : null;
        userProfile.country = obj.basicUserVO.countryId ? obj.basicUserVO.countryId : "";
        userProfile.countryName = obj.basicUserVO.country ? obj.basicUserVO.country.countryName : "";
        userProfile.customerId = obj.basicUserVO.id ? obj.basicUserVO.id : "";
        userProfile.companyId = obj.basicUserVO.companyId ? obj.basicUserVO.companyId : null;
        userProfile.otherCompanyName = obj.basicUserVO.companyDescription
            ? obj.basicUserVO.companyDescription
            : null;
        userProfile.isEnterprise =
            obj.basicUserVO.gtmScreened === "Y" || !obj.basicUserVO.gtmScreened ? true : false;

        userProfile.isFederatedUser = obj.basicUserVO.isFederatedUser;
        const companyVO = {};
        const list = [];

        const companyId = userProfile.companyId;

        if (obj.companyVO) {
            obj.companyVO.map((item: any) => {
                list.push({ label: item.companyName, value: item.companyId });
            });
        }

        if (companyId === OTHER_COMPANY_ID && obj.basicUserVO.otherCompanyName) {
            companyVO.isDisabled = true;
            companyVO.fieldType = "text";
            userProfile.companyName = OTHER_COMPANY;
        } else if (companyId === OTHER_COMPANY_ID) {
            userProfile.companyName = OTHER_COMPANY;
            //if company list contains, company other than "other", show drop down
            if (list.length && list[0].value !== OTHER_COMPANY_ID) {
                companyVO.fieldType = "select";
            } else {
                if (userProfile.companyName === "Other") {
                    // userProfile.companyName = "";
                }
                companyVO.fieldType = "text";
            }
            companyVO.isDisabled = false;
            companyVO.companyId = companyId;
        } else if (companyId === "") {
            if (obj.companyVO) {
                companyVO.isDisabled = false;
                companyVO.fieldType = "select";
                companyVO.companyId = companyId;
            } else {
                companyVO.isDisabled = false;
                companyVO.fieldType = "text";
                companyVO.companyId = companyId;
            }
        } else {
            companyVO.isDisabled = true;
            companyVO.fieldType = "select";
            companyVO.companyId = companyId;
        }
        companyVO.options = list;
        userProfile.company = obj.basicUserVO.company ? companyVO : {};
    }

    return userProfile;
}

export default formatUserInfoVO;
